'use strict'

import Style from 'ol/style/Style'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Circle from 'ol/style/Circle'
import { SVG } from '../SVG'
import TextLabel from '../TextLabel'
import { i18n } from '@/plugins/i18n'
import moment from 'moment'
import {
  CLASS_DEFINITION,
  NO_VALUE_CLASS,
  NO_VALUE_STYLE,
  SQUARE_SVG,
} from '@/components/styles/snowStyling/measurementMetadata'

function MeasurementStyle(name) {
  const param = name
  const resShowAllStations = 35
  const classes = CLASS_DEFINITION[param]
  const snowStyleCache = new Map()

  // eslint-disable-next-line complexity
  const styleFunction = function (feature, resolution) {
    const value = feature.get(param)
    if (value === undefined || value === null) {
      if (resolution > resShowAllStations) {
        return
      } else {
        return NO_VALUE_STYLE
      }
    }

    const cacheKey = getCacheKey(param, value)
    const labelValue = computeLabelValue(value, param)
    let snowStyle

    if (snowStyleCache.has(cacheKey)) {
      snowStyle = snowStyleCache.get(cacheKey)
    } else {
      const textLabel = new TextLabel()
      snowStyle = new Style({
        image: getSymbol(value),
        text: textLabel.get(labelValue, resolution),
      })
      snowStyleCache.set(cacheKey, snowStyle)
    }

    if (labelValue != null) {
      snowStyle.getText().setText(labelValue.toString())
    }

    return [snowStyle]
  }

  const getCacheKey = (param, value) => {
    return `${param}${getClass(value).style.fill}`
  }

  const getClass = function (value) {
    return classes.reduce(
      (prev, el) => (value <= el.t ? el : prev),
      NO_VALUE_CLASS
    )
  }
  const getSymbol = function (value) {
    const style = getClass(value).style
    return new Circle({
      radius: 5,
      fill: new Fill({ color: style.fill }),
      stroke: new Stroke({ color: style.stroke, width: 2 }),
    })
  }
  // eslint-disable-next-line complexity
  const legend = function (select) {
    let title
    switch (select) {
      case '1d':
        title = {
          count: 1,
          name: 'common.day',
          title: 'snow.label.newSnow',
          unit: 'common.unit.centimeter',
        }
        break
      case 'snowRainAltitude':
        title = {
          title: 'snow.label.snowRainAltitude',
          unit: 'common.unit.meterAboveSeaLevel',
        }
        break
    }
    const items = CLASS_DEFINITION[param]
      .slice()
      .reverse()
      .map((v) => {
        return { label: v.label, symbol: getLegendSymbol(v.t, param) }
      })
    if (title) {
      return {
        title: title,
        items: items,
      }
    } else {
      return items
    }
  }
  const getClasses = function () {
    return CLASS_DEFINITION[param].slice().reverse()
  }
  const getLegendSymbol = function (value) {
    const style = getClass(value).style
    const symbol =
      '<g style="fill:' +
      style.fill +
      '; stroke:' +
      style.stroke +
      '">' +
      SQUARE_SVG +
      '</g>'
    const svg = new SVG('0 0 25 15')
    return svg.createImage(symbol)
  }
  // eslint-disable-next-line complexity
  const mouseOverFunction = function (feature) {
    const value = feature.get(param)
    const id = feature.get('id')
    if (id) {
      const name = feature.get('name') || ''
      const coord = feature.getGeometry().getCoordinates()
      const height = coord[2] || ''
      const timestamp = feature.get('timestamp')
        ? moment.unix(feature.get('timestamp')).format('DD.MM.YY HH:mm')
        : ''
      const network = feature.get('network') || ''
      const observer = feature.get('observer') || ''

      if (network === 'OBSERVER') {
        let date = ''
        const commitDate = feature.get('cTimestamp')
          ? moment.unix(feature.get('cTimestamp')).format('YYYY-MM-DD')
          : ''
        if (feature.get('cTimestamp') && commitDate === feature.get('oDate')) {
          date = moment
            .unix(feature.get('timestamp'))
            .format('DD.MM.YYYY, HH:mm')
        } else {
          if (feature.get('oDate')) {
            date = moment(feature.get('oDate')).format('DD.MM.YYYY')
          }
        }
        return `
        <div>${observer}</div>
        <div>${i18n.global.t('mouseover.observation')}: ${date}</div>
        `
      } else {
        if (value !== null && value !== undefined) {
          return `
            <div>${i18n.global.t('station.id')}: ${id}</div>
            <div>${i18n.global.t('station.name')}: ${name}</div>
            <div>${i18n.global.t('station.height')}: ${height} ${i18n.global.t(
            'common.unit.meterAboveSeaLevel'
          )}</div>
            <div>${i18n.global.t(
              'measurement.label.timestamp'
            )}: ${timestamp}</div>
          `
        } else {
          return `
            <div>${i18n.global.t('station.id')}: ${id}</div>
            <div>${i18n.global.t('station.name')}: ${name}</div>
            <div>${i18n.global.t('station.height')}: ${height} ${i18n.global.t(
            'common.unit.meterAboveSeaLevel'
          )}</div>
          `
        }
      }
    } else {
      return null
    }
  }
  return {
    styleFunction: styleFunction,
    legend: legend,
    mouseOverFunction: mouseOverFunction,
    getClasses: getClasses,
  }
}

const computeLabelValue = function (value, param) {
  if (param === 'snowRainAlitude' || param === 'snowline') {
    return Math.round(value)
  } else {
    return value
  }
}

export default MeasurementStyle
