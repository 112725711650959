<template>
  <span>
    <span v-if="tabs.length > 1 || !hideHeaderByOne">
      <b-tabs
        id="tabsContainer"
        v-model="tabIndex"
        pills
        card
        no-fade
        class="nav-justified"
      >
        <b-tab
          v-for="tab in tabs"
          :key="tab.id"
          title-link-class="p-2"
          :active="tab.active"
        >
          <template #title>
            <span
              v-if="
                tab.withAval && !hideAvalWarn && hasMissAval && tabs.length > 1
              "
              class="warn-tab"
            >
              {{ tab.title }}
              <font-awesome-icon icon="fa-solid fa-exclamation-circle" />
            </span>
            <span v-else>{{ tab.title }}</span>
          </template>
          <slot name="tabContent" :tabElement="tab"></slot>
        </b-tab>
      </b-tabs>
    </span>
    <span v-else>
      <span v-for="tab in tabs" :key="tab.id">
        <slot name="tabContent" :tabElement="tab"></slot>
      </span>
    </span>
  </span>
</template>

<script>
import FeedbackTabsMenus from '@/components/observation/feedback/FeedbackTabsMenus'

export default {
  name: 'FeedbackTabs',
  props: ['hideHeaderByOne', 'hideAvalWarn', 'hasMissAval', 'setTabId'],
  data() {
    return {
      tabIndex: 0,
    }
  },

  created() {
    this.calcTabToSelect()
  },

  methods: {
    calcTabToSelect() {
      this.tabIndex = 0
      const tabs = this.tabs
      for (let index = 0; index < tabs.length; index++) {
        const tab = tabs[index]
        if (this.isTabSelected(tab)) {
          this.tabIndex = index
          tab.active = true
        }
      }
    },
    isTabSelected(tab) {
      return (
        (tab.groupId && tab.groupId === this.setTabId) ||
        (tab.studyplotId && tab.studyplotId === this.setTabId) ||
        (tab.ldId && tab.ldId === this.setTabId)
      )
    },
  },
  watch: {
    setTabId: function () {
      this.calcTabToSelect()
    },
  },
  computed: {
    tabs() {
      // GruppenTab
      const groupCount = this.$store.state.profile.userGroups.length
      const groupTabs = this.$store.state.profile.userGroups
        .map((g) => {
          return {
            id: g.id,
            groupId: g.id,
            studyplotId: undefined,
            isObserverGroup: true,
            isStudyplot: false,
            title: groupCount > 1 ? g.name : this.$t('ui.feedback.field'),
            menu: FeedbackTabsMenus.getObservationCommonMenu(),
            withAval: true,
            responsible: g.responsible,
          }
        })
        .sort((a, b) => {
          if (a.title < b.title) return -1
          if (a.title > b.title) return 1
          return 0
        })

      // Messfeld
      const studyplotCount = this.$store.state.profile.studyplots.length
      const studyplotTabs = this.$store.state.profile.studyplots
        .map((s) => {
          const groupToStudyplot = this.$store.state.profile.userGroups.find(
            (group) => {
              return group.studyplots.some((studyplot) => {
                return studyplot.id === s.id
              })
            }
          )

          return {
            id: s.id,
            groupId: groupToStudyplot ? groupToStudyplot.id : undefined,
            studyplotId: s.id,
            isObserverGroup: false,
            isStudyplot: true,
            title: studyplotCount > 1 ? s.id : this.$t('ui.studyplot'),
            menu: FeedbackTabsMenus.getStudyplotCommonMenu().filter(
              (e) => e.show || s.programs.includes(e.program)
            ),
            withAval: false,
            responsible: groupToStudyplot.responsible,
          }
        })
        .sort((a, b) => {
          if (a.title < b.title) return -1
          if (a.title > b.title) return 1
          return 0
        })

      // Assessment
      const LDTabs = this.$store.state.profile.assmAvalServices
        .map((ld) => {
          return {
            id: ld.id,
            ldId: ld.id,
            title: ld.name,
            menu: FeedbackTabsMenus.getAssessmentMenu(),
          }
        })
        .sort((a, b) => {
          if (a.title < b.title) return -1
          if (a.title > b.title) return 1
          return 0
        })

      // Tabs preparation
      let tabs = studyplotTabs.concat(groupTabs)
      if (groupTabs.length === 0) {
        tabs.push({
          title: this.$t('ui.feedback.field'),
          groupId: undefined,
          studyplotId: undefined,
          isObserverGroup: false,
          isStudyplot: false,
          menu: FeedbackTabsMenus.getObservationCommonMenu(),
          withAval: true,
        })
      }

      tabs = tabs.concat(LDTabs)

      // Studyplot-Management für Admin's
      if (this.$store.state.user.user.isAdmin) {
        tabs.push({
          id: 'studyplotManagement',
          title: this.$t('ui.feedback.studyplotManagement.title'),
          groupId: undefined,
          studyplotId: undefined,
          isObserverGroup: false,
          isStudyplot: false,
          menu: FeedbackTabsMenus.getStudyplotManagementMenu(),
          withAval: false,
          responsible: undefined,
        })
      }

      if (!this.setTabId) {
        const defaultTab = tabs.find(
          (tab) => tab.responsible === this.$store.getters.user.id
        )
        if (defaultTab) {
          defaultTab.active = true
        }
      }

      return tabs
    },
  },
}
</script>

<style>
.warn-tab {
  color: #dc3545;
  font-weight: bold;
}
.nav-pills > li > a.active {
  background-color: #337ab7 !important;
  color: white !important;
  height: 100%;
}
#tabsContainer .card-header {
  border: none;
  background-color: #ffffff !important;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0;
}
#tabsContainer .nav-item {
  background-color: #f7f7f7 !important;
  margin: 5px 5px;
}
#tabsContainer .card-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
}
#obsModal .modal-body {
  padding-bottom: 3rem;
}
</style>
